<header class="header">
    <div class="header__side">
        <About></About>
    </div>
    <h1 class="header__title">
        <a class="header__titleLink" href="/"><img src="{ logo }" alt="Eldritch Radio"></a>
    </h1>
    <div class="header__side txtright">
        <Settings></Settings>
    </div>
</header>

<script>
    import logo from '/src/assets/img/logo.svg'
    import About from '/src/components/About'
    import Settings from '/src/components/Settings'
</script>
